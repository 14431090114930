import { watchEffect, Ref, watch, nextTick, onMounted, inject } from "vue";
import { xoneAttributesHandler } from "../XoneAttributesHandler";

/**
 * @typedef {Object} CollGroups
 * @property {Array} tabs
 * @property {Array} fixedTop
 * @property {Array} fixedBottom
 * @property {Array} drawerLeft
 * @property {Array} drawerRight
 */

/**
 * Get coll groups
 * @param {Array} controls
 * @return {CollGroups}
 */
export const getCollGroups = (controls) => {
  // Tabs
  return {
    tabs: controls
      ?.filter((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          e.attributes.node === "group" &&
          !containerAttributes.fixed &&
          !containerAttributes.drawerOrientation
        );
      })
      .filter((e) => e.controls?.length !== 0),

    // Fixed top
    fixedTop: controls
      ?.filter((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          e.attributes.node === "group" &&
          containerAttributes.fixed &&
          containerAttributes.orientation === "top"
        );
      })
      .filter((e) => e.controls?.length !== 0),

    // Fixed bottom
    fixedBottom: controls
      ?.filter((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          e.attributes.node === "group" &&
          containerAttributes.fixed &&
          containerAttributes.orientation === "bottom"
        );
      })
      .filter((e) => e.controls?.length !== 0),

    // Drawer left
    drawerLeft: controls
      ?.filter((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          e.attributes.node === "group" &&
          !containerAttributes.fixed &&
          containerAttributes.drawerOrientation === "left"
        );
      })
      .filter((e) => e.controls?.length !== 0),

    // Drawer right
    drawerRight: controls
      ?.filter((e) => {
        const containerAttributes = xoneAttributesHandler.getContainerAttributes(
          e.attributes
        );
        return (
          e.attributes.node === "group" &&
          !containerAttributes.fixed &&
          containerAttributes.drawerOrientation === "right"
        );
      })
      .filter((e) => e.controls?.length !== 0),
  };
};

/**
 * Change group | Open / Close drawer
 * @param {String} id
 * @param {Object} groups
 * @param {Object} drawers
 * @param {Ref<string>} activeGroup
 * @param {Ref<string>} groupAnimation
 * @param {boolean} [isToggle]
 */
export const showGroup = (
  id,
  groups,
  drawers,
  activeGroup,
  groupAnimation,
  isToggle = false
) => {
  // Tabs
  const tabGroup = groups.tabs.find(
    (/** @type {*} */ e) => e.attributes.id === id
  );
  if (tabGroup) {
    groupAnimation.value =
      Number(activeGroup.value) > Number(id) ? "slide-right" : "slide-left";
    setTimeout(() => (groupAnimation.value = ""), 250);
    activeGroup.value = id;
    document.getElementById("content")?.scroll(0, 0);
    return;
  }
  // Drawer left
  const leftDrawerGroup = groups.drawerLeft.find(
    (/** @type {*} */ e) => e.attributes.id === id
  );
  if (leftDrawerGroup) {
    drawers.showRight = false;
    if (drawers.showLeft || (isToggle && drawers.showLeft)) {
      drawers.showLeft = false;
      // setTimeout(() => (drawers.overflow = "hidden"), 250);
    } else {
      // drawers.overflow = "visible";
      drawers.showLeft = true;
    }
    return;
  }
  // Drawer right
  const rightDrawerGroup = groups.drawerRight.find(
    (/** @type {*} */ e) => e.attributes.id === id
  );
  if (rightDrawerGroup) {
    drawers.showLeft = false;
    if (drawers.showRight || (isToggle && drawers.showRight)) {
      drawers.showRight = false;
      // setTimeout(() => (drawers.overflow = "hidden"), 250);
    } else {
      // drawers.overflow = "visible";
      drawers.showRight = true;
    }
    return;
  }
};

/**
 * Slide group
 * @param {number} step
 * @param {Object} groups
 * @param {Ref<string>} activeGroup
 * @param {Ref<string>} groupAnimation
 */
export const slideGroup = (step, groups, activeGroup, groupAnimation) => {
  const currentGroup = groups.tabs.find(
    (/** @type {*} */ e) => e.attributes.id === activeGroup.value
  );
  if (!currentGroup) return;
  const groupIndex = groups.tabs.indexOf(currentGroup);
  const newGroup = groups.tabs[groupIndex + step];
  if (!newGroup) return;
  groupAnimation.value = step < 0 ? "slide-right" : "slide-left";
  setTimeout(() => (groupAnimation.value = ""), 250);
  activeGroup.value = newGroup.attributes.id;
};

/**
 * Get width from drawer elements
 * @param {Object} drawers
 * @param {Ref<number>} containerWidth
 * @param {String} breadcumbId
 */
export const getDrawersWidth = (drawers, containerWidth, breadcumbId) => {
  {
    /**
     * drawer left element
     * @type {HTMLElement}
     */
    const drawerLeftElement = document.getElementById(
      `xone-sidenav-left-${breadcumbId}`
    );
    /**
     * drawer right element
     * @type {HTMLElement}
     */
    const drawerRightElement = document.getElementById(
      `xone-sidenav-right-${breadcumbId}`
    );
    if (!drawers.drawerLeftElement && !containerWidth.value) return;
    drawers.leftWidth = drawerLeftElement?.offsetWidth;
    drawers.rightWidth = drawerRightElement?.offsetWidth;
    watchEffect(() => {
      if (drawers.currentWindowWidth === containerWidth.value) return;
      drawers.currentWindowWidth = containerWidth.value;
      nextTick().then(() => {
        const setDrawersWidth = () => {
          drawers.leftWidth = drawerLeftElement?.offsetWidth;
          drawers.rightWidth = drawerRightElement?.offsetWidth;
        };
        setTimeout(() => setDrawersWidth(), 250);
      });
    });
  }
};

/**
 * Watch contents element height changes
 * @param {Ref<Number>} contentsElementHeight
 * @param {Ref<Number>} containerHeight
 * @param {Ref<Number>} containerWidth
 * @param {Ref<HTMLElement>} fixedTopElement
 * @param {Ref<HTMLElement>} fixedBottomElement
 * @param {string} breadcumbId
 */
export const watchContentElementSize = (
  contentsElementHeight,
  containerHeight,
  containerWidth,
  fixedTopElement,
  fixedBottomElement,
  breadcumbId
) => {
  const resize = () => {
    resizeContentElementSize(
      contentsElementHeight,
      containerHeight,
      fixedTopElement,
      fixedBottomElement
    );
  };
  onMounted(() => {
    resize();
    watch(
      () => containerHeight.value,
      () => resize()
    );
    watch(
      () => containerWidth.value,
      () => resize()
    );

    const lastBreadcumb = inject("lastBreadcumb");
    watchEffect(() => {
      if (lastBreadcumb.value?.id === breadcumbId) resize();
    });
  });
};

/**
 * Resize content element
 * @param {Ref<Number>} contentsElementHeight
 * @param {Ref<Number>} containerHeight
 * @param {Ref<HTMLElement>} fixedTopElement
 * @param {Ref<HTMLElement>} fixedBottomElement
 */
const resizeContentElementSize = async (
  contentsElementHeight,
  containerHeight,
  fixedTopElement,
  fixedBottomElement
) => {
  await nextTick();
  Promise.resolve().then(() => {
    contentsElementHeight.value =
      containerHeight.value -
      (fixedTopElement.value?.offsetHeight ?? 0) -
      (fixedBottomElement.value?.offsetHeight ?? 0);
  });
};

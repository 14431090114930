<template>
  <!-- 
  ----
  Groups fixed top 
  ----
  -->
  <div
    ref="fixedTopElement"
    v-if="groups.fixedTop.length !== 0"
    :id="`fixed-top-${breadcumb.id}`"
    class="xone-fixed-top"
  >
    <Container
      v-for="fixed in groups.fixedTop"
      :key="`fixed-top-${fixed.attributes.name}`"
      :xoneDataObject="xoneDataObject"
      :control="fixed"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
    ></Container>
  </div>
  <!-- 
  ----
  Groups header buttons
  ----
  -->
  <Groups
    v-if="!attributes.noTab"
    :groups="groups.tabs"
    @onHeightChanged="onGroupsHeaderHeightChanged"
  ></Groups>

  <div
    class="xone-groups-content-box"
    :style="{
      position: groups.drawerLeft.length !== 0 ? 'absolute' : '',
      maxHeight: contentElementHeight && `${contentElementHeight}px`,
      overflow: drawers.overflow,
    }"
  >
    <!-- 
    ----
    Groups content
    ----
    -->
    <div
      ref="contentElement"
      class="xone-group"
      :style="{
        marginLeft: null,
        maxHeight:
          contentElementHeight &&
          `${contentElementHeight - groupsHeaderHeight}px`,
      }"
    >
      <Container
        :class="('tabcontent', groupAnimation)"
        v-for="group in groups.tabs"
        :key="`group-${group.attributes.name}`"
        :xoneDataObject="xoneDataObject"
        :control="group"
        :containerWidth="containerWidth"
        :containerHeight="contentElementHeight - groupsHeaderHeight"
      ></Container>
    </div>
    <!-- 
    ----
    Drawers
    ----
    -->
    <!-- Close Drawers -->
    <div
      class="xone-close-drawer"
      :class="[drawers.showLeft || drawers.showRight ? 'fade-in' : 'fade-out']"
      v-if="drawers.showLeft || drawers.showRight"
      @click="drawers.closeDrawers()"
    ></div>
    <!-- Drawer Left -->
    <Container
      v-if="groups.drawerLeft.length !== 0"
      :id="`xone-sidenav-left-${breadcumb.id}`"
      class="xone-sidenav-left"
      :xoneDataObject="xoneDataObject"
      :control="groups.drawerLeft[0]"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
      :style="{
        opacity: drawers.showLeft ? 1 : 0,
        marginLeft: `${drawers.showLeft ? 0 : -drawers.leftWidth}px`,
        pointerEvents: drawers.showLeft ? 'all' : 'none',
      }"
    ></Container>
    <!-- Drawer Right -->
    <Container
      v-if="groups.drawerRight.length !== 0"
      :id="`xone-sidenav-right-${breadcumb.id}`"
      class="xone-sidenav-right"
      :xoneDataObject="xoneDataObject"
      :control="groups.drawerRight[0]"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
      :style="{
        opacity: drawers.showRight ? 1 : 0,
        marginRight: `${drawers.showRight ? 0 : -drawers.rightWidth}px`,
        pointerEvents: drawers.showRight ? 'all' : 'none',
      }"
    ></Container>
  </div>
  <!--
  ----
  Groups fixed bottom
  ----
  -->
  <div
    ref="fixedBottomElement"
    :id="`fixed-bottom-${breadcumb.id}`"
    class="xone-fixed-bottom"
  >
    <Container
      v-for="fixed in groups.fixedBottom"
      :key="`fixed-bottom-${fixed.attributes.name}`"
      :xoneDataObject="xoneDataObject"
      :control="fixed"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
    ></Container>
  </div>
</template>

<script>
// vue
import {
  computed,
  inject,
  onBeforeMount,
  onMounted,
  onUnmounted,
  provide,
  reactive,
  ref,
  Ref,
  watchEffect,
  ComputedRef,
  nextTick,
  watch,
} from "vue";
// components
import Groups from "@/components/Groups";
// composables
import { SwipeHandler } from "../composables/SwipeHandler";
import AppDataHandler from "../composables/AppDataHandler";
import {
  getCollGroups,
  showGroup,
  slideGroup,
  getDrawersWidth,
  watchContentElementSize,
  CollGroups,
} from "../composables/helperFunctions/GroupsHelper";
import {
  xoneAttributesHandler,
  ContainerAttributes,
} from "../composables/XoneAttributesHandler";
import { setShowGroupCallback, setXoneDataObject } from "../composables/XoneUI";
import { XoneDataObject } from "../composables/appData/core/XoneDataObject";
import { XoneView } from "../composables/XoneViewsHandler";

export default {
  props: {
    breadcumb: {
      type: Object,
      default: null,
    },
  },
  components: {
    Groups,
  },
  setup(props) {
    /**
     * Get XoneDataObject
     * @type {XoneDataObject}
     */
    const xoneDataObject = AppDataHandler.getDataObject(props.breadcumb.id);

    /**
     * Last breadcumb in stack
     * @type {ComputedRef<string>}
     */
    const lastBreadcumb = inject("lastBreadcumb");

    // Provide breadcumbId info to child components
    provide("breadcumbId", props.breadcumb.id);

    /**
     * Bind data model
     * @type {Ref<Object>}
     */
    const dataModel = ref(xoneDataObject.model);
    xoneDataObject.setModel(dataModel.value);

    /**
     * Layout model
     * @type {Ref<Object>}
     */
    const layout = ref();

    /**
     * Viewport size conditions sm / md / lg
     * @type {Ref<string>}
     */
    const sizeConditions = inject("sizeConditions");

    /**
     * orientation vertical / horizontal
     * @type {Ref<string>}
     */
    const orientation = inject("orientation");

    const getLayout = () => {
      layout.value = xoneDataObject.getLayout();
      xoneDataObject.setLayout(layout.value);
    };

    getLayout();

    watch(
      () => sizeConditions.value,
      () => getLayout()
    );

    watch(
      () => orientation.value,
      () => getLayout()
    );

    /**
     * Collection attributes
     * @type {ComputedRef<ContainerAttributes>}
     */
    const attributes = computed(() =>
      xoneAttributesHandler.getContainerAttributes(layout.value.attributes)
    );

    // Execute node before-edit
    onBeforeMount(() =>
      xoneDataObject.ExecuteNode("before-edit").catch(console.error)
    );

    // Execute node after-edit
    onMounted(() =>
      xoneDataObject.ExecuteNode("after-edit").catch(console.error)
    );

    //
    // Manage coll groups

    /**
     * Groups
     * @type {ComputedRef<CollGroups>}
     */
    const groups = computed(() => getCollGroups(layout.value?.controls));

    /**
     * Active Group
     * @type {Ref<string>}
     */
    const activeGroup = ref(
      groups.value.tabs.length !== 0 && groups.value.tabs[0].attributes.id
    );

    const changeGroup = (id, isToggle = false) =>
      showGroup(
        id,
        groups.value,
        drawers,
        activeGroup,
        groupAnimation,
        isToggle
      );

    /**
     * Window Size
     * @type {{containerWidth: Ref<number>, containerHeight: Ref<number>}}
     */
    const { containerWidth, containerHeight } = inject("containerSize");

    /**
     * Group tab content element
     * @type {Ref<number>}
     */
    const contentElementHeight = ref(containerWidth.value);

    const fixedTopElement = ref();
    const fixedBottomElement = ref();

    // Watch contents element height changes
    watchContentElementSize(
      contentElementHeight,
      containerHeight,
      containerWidth,
      fixedTopElement,
      fixedBottomElement,
      props.breadcumb.id
    );

    /**
     * xone View
     * @type {XoneView}
     */
    const xoneView = new XoneView(xoneDataObject);
    provide("xoneView", xoneView);

    // Current XoneDataObject focused
    watchEffect(() => {
      if (props.breadcumb.id === lastBreadcumb.value?.id) {
        // Send changeGroup to XoneUI showGroup method
        setShowGroupCallback(changeGroup);
        // Send refresh items
        setXoneDataObject(xoneDataObject);
      }
    });

    // Provide groupHandler to child components
    provide("groupHandler", {
      // active group
      activeGroup: activeGroup,
      // change group callback function
      changeGroup,
    });

    /**
     * Group transition animation
     * @type {Ref<string>}
     */
    const groupAnimation = ref("");

    /**
     * contentElement
     * @type {Ref<HTMLElement>}
     */
    const contentElement = ref();
    /**
     * Change current group by swipe
     * @type {SwipeHandler}
     */
    const swipeHandler = new SwipeHandler(
      // swipe group callback function
      (step) => slideGroup(step, groups.value, activeGroup, groupAnimation)
    );
    // Initialize group swipe handler
    onMounted(() => swipeHandler.init(contentElement.value));
    // clear group swipe handler
    onUnmounted(() => swipeHandler?.clear());

    /**
     * drawers
     */
    const drawers = reactive({
      leftWidth: 0,
      rightWidth: 0,
      showLeft: false,
      showRight: false,
      overflow: "hidden",
      closeDrawers: () => {
        drawers.showLeft = false;
        drawers.showRight = false;
      },
      currentWindowWidth: containerWidth.value,
    });

    // Get drawers width
    nextTick(() =>
      getDrawersWidth(drawers, containerWidth, props.breadcumb.id)
    );

    // Provide object info
    provide("objectInfo", { isContents: false });

    const groupsHeaderHeight = ref(0);

    const onGroupsHeaderHeightChanged = (value) =>
      (groupsHeaderHeight.value = value);

    return {
      attributes,
      groups,
      drawers,
      groupAnimation,
      containerWidth,
      containerHeight,
      xoneDataObject,
      fixedTopElement,
      fixedBottomElement,
      contentElementHeight,
      contentElement,
      onGroupsHeaderHeightChanged,
      groupsHeaderHeight,
    };
  },
};
</script>

<style scoped>
.xone-fixed-top {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.24);
}

.xone-groups-content-box {
  width: var(--app-width);
  position: relative;
  display: flex;
  /* overflow: hidden; */
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
}

.xone-close-drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  pointer-events: all;
}

.fade-in {
  animation: fadeIn05 0.3s;
  opacity: 0.5;
}

.fade-out {
  animation: fadeOut05 0.3s;
  opacity: 0;
}

.xone-group {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 1;
  overflow: hidden;
  height: auto;
  transition: opacity 0s, margin-left 0.3s, margin-right 0.3s;
}

.xone-sidenav-left {
  position: absolute;
  flex-shrink: 0;
  top: auto;
  left: 0;
  width: auto;
  pointer-events: all;
  opacity: 0;
  transition: all 0.3s;
  /* box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.24); */
}

.xone-sidenav-right {
  position: absolute;
  flex-shrink: 0;
  top: auto;
  right: 0;
  width: auto;
  pointer-events: all;
  opacity: 0;
  transition: all 0.3s;
  /* box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.24); */
}

/* Change group animations */
.slide-left {
  animation: slideLeft 0.2s; /* ease-out */
}

.slide-right {
  animation: slideRight 0.2s; /*ease-out*/
}

@keyframes fadeIn05 {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
</style>